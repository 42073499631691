import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { Receipt } from "./Receipt";

export enum CreditNoteStatus {
    OPEN = "OPEN",
    PAID = "PAID",
}

export const getCreditNoteStatusName = (status: CreditNoteStatus) => {
    switch (status) {
        case CreditNoteStatus.OPEN:
            return "Offen";
        case CreditNoteStatus.PAID:
            return "Bezahlt";
    }
};
export class CreditNote extends Receipt {
    public creditNoteStatus: CreditNoteStatus;
    public paymentInfos: CustomerPaymentInfos;
    public customerTaxId: string;
    public paymentDate: string | null;

    constructor(initialValues?: Partial<CreditNote>) {
        super(initialValues);
        this.creditNoteStatus = initialValues?.creditNoteStatus ?? CreditNoteStatus.OPEN;
        this.paymentDate = initialValues?.paymentDate ?? null;
        this.paymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.paymentInfos,
        };
        this.customerTaxId = initialValues?.customerTaxId ?? "";
    }
}
