import { immerable } from "immer";
import { Rounding } from "@farmact/model/src/model/Rounding";
import { BillReminderSettings, DEFAULT_BILL_REMINDER_SETTINGS } from "./BillReminder";
import { BillSettings } from "./BillSettings";
import { CountryCode } from "./CountryCode";
import { CreditNoteSettings } from "./CreditNoteSettings";
import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { DEFAULT_DATEV_PROPERTIES, DatevProperties } from "./DatevProperties";
import { DeliveryNoteSettings } from "./DeliveryNoteSettings";
import { MailSettings } from "./MailSettings";
import { OfferSettings } from "./OfferSettings";
import { IdentifiablePhoto } from "./Order";
import { Vertex } from "./Vertex";

export class OperatingUnit {
    [immerable] = true;
    public archived: boolean;
    public bankAccounts: BankAccount[];
    public connectedBanksNeedAttention: boolean;
    public billSettings: BillSettings;
    public billReminderSettings: BillReminderSettings;
    public city: string;
    public companyNumber: string; // Betriebsnummer
    public countryCode: CountryCode;
    public creditNoteSettings: CreditNoteSettings;
    public customerPaymentInfos: CustomerPaymentInfos;
    public datev: DatevProperties;
    public deliveryNoteSettings: DeliveryNoteSettings;
    public email: string;
    public fax: string;
    public id: string;
    public latLng?: Vertex;
    public legalInfo: string;
    public mailSettings: MailSettings;
    public logo: IdentifiablePhoto | null;
    public receiptLogoSettings: ReceiptLogoSettings;
    public mobile: string;
    public name: string;
    public notes: string;
    public offerSettings: OfferSettings;
    public rounding: Rounding;
    public salesTaxId: string; // UST-ID
    public state: string;
    public street: string;
    public taxNumber: string; // Steuernummer
    public telephone: string;
    public vat?: number;
    public website: string;
    public zip: string;

    constructor(initialValues?: Partial<OperatingUnit>) {
        this.archived = initialValues?.archived ?? false;
        this.bankAccounts = initialValues?.bankAccounts ?? [];
        this.connectedBanksNeedAttention = initialValues?.connectedBanksNeedAttention ?? false;
        this.billSettings = new BillSettings(initialValues?.billSettings);
        this.billReminderSettings = initialValues?.billReminderSettings ?? DEFAULT_BILL_REMINDER_SETTINGS;
        this.city = initialValues?.city ?? "";
        this.companyNumber = initialValues?.companyNumber ?? "";
        this.countryCode = initialValues?.countryCode ?? "DE";
        this.creditNoteSettings = new CreditNoteSettings(initialValues?.creditNoteSettings);
        this.offerSettings = new OfferSettings(initialValues?.offerSettings);
        this.receiptLogoSettings = initialValues?.receiptLogoSettings ?? {
            logoPosition: LogoPosition.RIGHT,
            offset: -10,
        };
        this.customerPaymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.customerPaymentInfos,
        };
        this.datev = { ...DEFAULT_DATEV_PROPERTIES, ...initialValues?.datev };
        this.deliveryNoteSettings = new DeliveryNoteSettings(initialValues?.deliveryNoteSettings);
        this.email = initialValues?.email ?? "";
        this.fax = initialValues?.fax ?? "";
        this.id = initialValues?.id ?? "";
        this.latLng = initialValues?.latLng;
        this.legalInfo = initialValues?.legalInfo ?? "";
        this.logo =
            initialValues?.logo && (!initialValues.logo.imageSrc || !initialValues.logo.storagePath)
                ? null
                : initialValues?.logo ?? null;
        this.mailSettings = new MailSettings(initialValues?.mailSettings);
        this.mobile = initialValues?.mobile ?? "";
        this.name = initialValues?.name ?? "";
        this.notes = initialValues?.notes ?? "";
        this.offerSettings = new OfferSettings(initialValues?.offerSettings);
        this.rounding = initialValues?.rounding ?? { hours: null };
        this.salesTaxId = initialValues?.salesTaxId ?? "";
        this.state = initialValues?.state ?? "";
        this.street = initialValues?.street ?? "";
        this.taxNumber = initialValues?.taxNumber ?? "";
        this.telephone = initialValues?.telephone ?? "";
        this.vat = initialValues?.vat;
        this.website = initialValues?.website ?? "";
        this.zip = initialValues?.zip ?? "";
    }
}

export type BankAccount = {
    id: string;
    bankName?: string;
    iban?: string;
    bic?: string;
};

export type ReceiptLogoSettings = {
    logoPosition: LogoPosition;
    /**
     * Offset in mm
     * negativ left
     * positiv right
     * */
    offset: number;
};

export enum LogoPosition {
    LEFT = "LogoPosition_LEFT",
    CENTER = "LogoPosition_CENTER",
    RIGHT = "LogoPosition_RIGHT",
}
