import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { Receipt } from "./Receipt";

export enum OfferStatus {
    DRAFT = "DRAFT",
    OPEN = "OPEN",
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
}

export const getOfferStatusName = (status: OfferStatus) => {
    switch (status) {
        case OfferStatus.DRAFT:
            return "Entwurf";
        case OfferStatus.OPEN:
            return "Offen";
        case OfferStatus.APPROVED:
            return "Angenommen";
        case OfferStatus.DECLINED:
            return "Abgelehnt";
    }
};
export class Offer extends Receipt {
    public offerStatus: OfferStatus;
    public createdBillId: string | null;
    public paymentInfos: CustomerPaymentInfos;

    constructor(initialValues?: Partial<Offer>) {
        super(initialValues);
        this.offerStatus = initialValues?.offerStatus ?? OfferStatus.DRAFT;
        this.createdBillId = initialValues?.createdBillId ?? null;
        this.paymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.paymentInfos,
        };
    }
}
