import { DatevTransfer } from "./DatevTransfer";
import { Supplier } from "./Supplier";

export abstract class IncomingReceipt {
    public createdAt: string;
    public date: string;
    public datevTransfer: DatevTransfer | null;
    public id: string;
    public receiptNumber: string | null;
    public recipient: IncomingReceiptPersonalInformation;
    public sender: IncomingReceiptPersonalInformation;
    public storagePath: string;
    public supplierId: Supplier["id"] | null;
    readonly _searchableSubstrings: string[] = [];

    constructor(initialValues?: Partial<IncomingReceipt>) {
        this.createdAt = initialValues?.createdAt ?? new Date().toISOString();
        this.date = initialValues?.date ?? new Date().toISOString();
        this.datevTransfer = initialValues?.datevTransfer ?? null;
        this.id = initialValues?.id ?? "";
        this.receiptNumber = initialValues?.receiptNumber ?? null;
        this.recipient = initialValues?.recipient ?? { name: "", address: "", email: "" };
        this.sender = initialValues?.sender ?? { name: "", address: "", email: "" };
        this.storagePath = initialValues?.storagePath ?? "";
        this.supplierId = initialValues?.supplierId ?? null;
        this._searchableSubstrings = initialValues?._searchableSubstrings ?? [];
    }
}
type IncomingReceiptPersonalInformation = {
    name: string;
    address: string;
    email: string;
};

export enum IncomingReceiptType {
    INCOMING_BILL = "INCOMING_BILL",
}
